
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('li', { 'className': 'product' }, _createElement('div', {
                    'className': 'product-item',
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'card ajax-loaded' }, _createElement('div', { 'className': 'card-product' }, _createElement('div', { 'className': 'card-product__wrapper' }, _createElement('div', { 'className': 'card__badge badge-right halo-productBadges halo-productBadges--right' }, this.on_sale ? _createElement('span', {
                    'className': 'badge sale-badge',
                    'aria-hidden': 'true',
                    'key': '295'
                }, 'Sale') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'badge sold-out-badge',
                    'aria-hidden': 'true',
                    'key': '390'
                }, 'Sold Out') : null), _createElement('a', {
                    'className': 'card-media card-media--adapt media--hover-effect media--loading-effect',
                    'href': this.url
                }, _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy',
                    'className': 'motion-reduce lazyloaded'
                }), this.image2 ? _createElement('img', {
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'loading': 'lazy',
                    'className': 'motion-reduce lazyloaded',
                    'key': '817'
                }) : null), _createElement('div', { 'className': 'card-product__group group-left' }, _createElement('div', {
                    'data-wlh-id': this.id,
                    'data-wlh-link': this.url,
                    'data-wlh-variantid': this.variant_ids[0],
                    'data-wlh-price': this.price,
                    'data-wlh-name': this.removeHTML(this.title),
                    'data-wlh-image': this.image,
                    'data-wlh-mode': 'icon_only',
                    'data-wlh-view': 'Collection',
                    'className': 'wishlist-hero-custom-button wishlisthero-floating cm_wishlist-button cm_wishlist-id--' + this.id
                }), _createElement('div', { 'className': 'card-product__group-item card-quickview card-quickviewIcon show-mb' }, _createElement('button', {
                    'type': 'button',
                    'title': 'Quick View',
                    'className': 'quickview-icon',
                    'data-product-id': this.id,
                    'data-open-quick-view-popup': true,
                    'data-product-handle': this.handle
                }, _createElement('span', { 'className': 'visually-hidden' }, 'Quick View'), _createElement('span', { 'className': 'text' }, ' Quick View '), [_createElement('svg', {
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'viewBox': '0 0 511.999 511.999',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon icon-eyes',
                        'key': '18960'
                    }, _createElement('path', { 'd': 'M508.745,246.041c-4.574-6.257-113.557-153.206-252.748-153.206S7.818,239.784,3.249,246.035 c-4.332,5.936-4.332,13.987,0,19.923c4.569,6.257,113.557,153.206,252.748,153.206s248.174-146.95,252.748-153.201 C513.083,260.028,513.083,251.971,508.745,246.041z M255.997,385.406c-102.529,0-191.33-97.533-217.617-129.418 c26.253-31.913,114.868-129.395,217.617-129.395c102.524,0,191.319,97.516,217.617,129.418 C447.361,287.923,358.746,385.406,255.997,385.406z' }), _createElement('path', { 'd': 'M255.997,154.725c-55.842,0-101.275,45.433-101.275,101.275s45.433,101.275,101.275,101.275  s101.275-45.433,101.275-101.275S311.839,154.725,255.997,154.725z M255.997,323.516c-37.23,0-67.516-30.287-67.516-67.516 s30.287-67.516,67.516-67.516s67.516,30.287,67.516,67.516S293.227,323.516,255.997,323.516z' }))]))))), _createElement('div', { 'className': 'card-information' }, _createElement('div', { 'className': 'card-information__wrapper text-left' }, _createElement('div', { 'className': 'card-information__group card-information__group-2' }, _createElement('div', { 'className': 'card-vendor' }, _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } })))), _createElement('a', {
                    'className': 'card-title link-underline card-title-ellipsis',
                    'href': this.url
                }, _createElement('span', mergeProps({ 'className': 'text' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'card-price' }, _createElement('div', { 'className': 'price' + (this.on_sale ? ' price--on-sale' : '') }, _createElement('dl', {}, _createElement('div', { 'className': 'price__regular' }, _createElement('dd', { 'className': 'price__last' }, this.price_varies ? _createElement('span', { 'key': '3738' }, 'From: ') : null, _createElement('span', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.price)))), _createElement('div', { 'className': 'price__sale' }, _createElement('dd', { 'className': 'price__compare' }, _createElement('s', { 'className': 'price-item price-item--regular' }, this.formatPrice(this.compare_at_price))), _createElement('dd', { 'className': 'price__last' }, _createElement('span', { 'className': 'price-item price-item--sale' }, this.formatPrice(this.price)))))))), _createElement('div', { 'className': 'card-action__group' }, _createElement('div', { 'className': 'card-action' }, !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'data-product-id': this.id,
                    'encType': 'multipart/form-data',
                    'key': '4504'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': '1'
                }), _createElement('button', {
                    'id': 'product-add-to-cart',
                    'className': 'button button-ATC product-form__submit button button--primary',
                    'type': 'submit',
                    'name': 'add',
                    'data-btn-addtocart': this.id,
                    'data-available': '1'
                }, '\n                Add to cart\n              ')) : null, this.out_of_stock ? _createElement('button', {
                    'disabled': 'disabled',
                    'className': 'button button-ATC',
                    'data-product-handle': this.handle,
                    'key': '5101'
                }, '\n              Out of Stock\n            ') : null, !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('a', {
                    'href': this.url,
                    'className': 'button button-ATC',
                    'data-product-handle': this.handle,
                    'key': '5279'
                }, '\n              Choose Options\n            ') : null))))), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '55450'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '5944'
                }) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products halo-block halo-product-block halo-recommendations-block' }, _createElement('div', { 'className': 'halo-block-header cm_related-products-title-container' }, _createElement('h2', { 'className': 'title cm_related-products-title' }, 'Related Products')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []