//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-488:_4208,_6092,_4492,_8468,_700,_5076,_1501,_5860;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-488')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-488', "_4208,_6092,_4492,_8468,_700,_5076,_1501,_5860");
        }
      }catch (ex) {
        console.error(ex);
      }